import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=324cccc0&scoped=true"
import script from "./Home.vue?vue&type=script&lang=ts"
export * from "./Home.vue?vue&type=script&lang=ts"
import style0 from "./Home.vue?vue&type=style&index=0&id=324cccc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324cccc0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeInfoSection: require('/app/components/home/InfoSection.vue').default,MapBanner: require('/app/components/map/MapBanner.vue').default,MapWrapper: require('/app/components/map/MapWrapper.vue').default,HomeUserRating: require('/app/components/home/UserRating.vue').default,HomeOfficialPartnersText: require('/app/components/home/OfficialPartnersText.vue').default,HomeOfficialPartners: require('/app/components/home/OfficialPartners.vue').default,HomeAdvantages: require('/app/components/home/Advantages.vue').default,HomeSummary123: require('/app/components/home/Summary123.vue').default,HomeHowToVideo: require('/app/components/home/HowToVideo.vue').default,HomePricing: require('/app/components/home/Pricing.vue').default,HomeUserReviews: require('/app/components/home/UserReviews.vue').default,HomeProtectionPackages: require('/app/components/home/ProtectionPackages.vue').default,HomeKnownFrom: require('/app/components/home/KnownFrom.vue').default,GeneralUtilsInvoiceBanner: require('/app/components/general/utils/InvoiceBanner.vue').default})
